<template>
  <div class="page">
    <div class="card">
      <p style="width: 100%;text-align: center">每月新增企业入驻</p>
      <Legend name="注册企业数"></Legend>
      <Chart type="line" :options="{legend: {display:false}}" :data="companiesNumber"/>
    </div>
    <div class="card">
      <p style="width: 100%;text-align: center">入驻企业总数</p>
      <Legend name="入驻企业数" />
      <Chart type="line" :options="{legend: {display:false}}" :data="companiesTotal"/>
    </div>
    <div class="card">
      <p style="width: 100%;text-align: center">企业增长趋势图</p>
      <Legend name="入驻企业数" />
      <Chart type="line" :options="{legend: {display:false}}" :data="companiesAdd"/>
    </div>
  </div>
</template>

<script>
import Legend from "@/views/platform/dataDiagram/legend";
export default {
  name: "dataDiagram",
  components: {Legend},
  data() {
    return {
      companiesNumber:null,
      companiesAdd: null,
      companiesTotal: null
    }
  },

  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      this.$http.get('/Statistics/companiesNumber').then((res) => {


        let companiesNumber = res.data.companiesNumber
        let labels = []
        let data = []

        for (let i = 0; i < companiesNumber.length; i++) {
          labels.push(companiesNumber[i].months)
          data.push(companiesNumber[i].count)
        }

        this.companiesNumber = {
          labels: labels,
          datasets: [
            {
              label: '注册企业数',
              fill: false,
              borderColor: '#42A5F5',
              data: data
            }
          ]
        }

        let companiesTotal = res.data.companiesTotal
        labels = []
        data = []
        for (let i = 0; i < companiesTotal.length; i++) {
          labels.push(companiesTotal[i].months)
          data.push(companiesTotal[i].total)
        }
        this.companiesTotal = {
          labels: labels,
          datasets: [
            {
              label: '入驻企业数',
              fill: false,
              borderColor: '#42A5F5',
              data: data
            }
          ]
        }
        let companiesAdd = res.data.companiesAdd
        labels = []
        data = []
        for (let i = 0; i < companiesAdd.length; i++) {
          labels.push(companiesAdd[i].months)
          data.push(companiesAdd[i].add)
        }
        this.companiesAdd = {
          labels: labels,
          datasets: [
            {
              label: '入驻企业数',
              fill: false,
              borderColor: '#42A5F5',
              data: data
            }
          ]
        }
      })
    }
  }
}
</script>

<style scoped>
.card{
  width: calc(50% - 7px);
}
.page{
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  background: none;
  border: none;
}
</style>